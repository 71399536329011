<template>
  <div>
    <b-container fluid class="mt-9">
      <!--Widgets-->
      <b-row>
        <b-col xl="8">
          <b-col xl="12">
            <b-card-group deck>
              <card gradient="default" no-body="">
                <b-card-body>
                  <div class="mb-2">
                    <b-row class="mb-2">
                      <b-col xl="12">
                        <sup class="text-white">STAKE • TOTAL</sup>
                      </b-col>
                      <b-col xl="12">
                        <span class="h1 text-white">2.6B MBT </span>
                      </b-col>

                      <b-col xl="8">
                        <span class=" text-white">Indexer Stake </span>
                      </b-col>
                      <b-col xl="4">
                        <span class=" text-white"> 705.8M MBT </span>
                      </b-col>

                      <b-col xl="8">
                        <span class=" text-white">Delegated Stake</span>
                      </b-col>
                      <b-col xl="4">
                        <span class=" text-white"> 1.9B MBT </span>
                      </b-col>
                    </b-row>
                    <hr class="my-1" style="background: white;" />
                    <b-row class="mb-2">
                      <b-col xl="8">
                        <span class=" text-white">Allocated Stake </span>
                      </b-col>
                      <b-col xl="4">
                        <span class=" text-white"> 2.7B MBT </span>
                      </b-col>

                      <b-col xl="8">
                        <span class=" text-white">Delegated Stake</span>
                      </b-col>
                      <b-col xl="4">
                        <span class=" text-white"> -47.5M MBT </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </card>

              <card gradient="default" no-body="">
                <b-card-body>
                  <div class="mb-2">
                    <b-row class="mb-2">
                      <b-col xl="12">
                        <sup class="text-white">INDEXING REWARDS • TOTAL</sup>
                      </b-col>
                      <b-col xl="12">
                        <span class="h1 text-white">106.5M MBT </span>
                      </b-col>

                      <b-col xl="8">
                        <span class=" text-white">Indexers </span>
                      </b-col>
                      <b-col xl="4">
                        <span class=" text-white"> 39.0M MBT</span>
                      </b-col>

                      <b-col xl="8">
                        <span class=" text-white">Delegators</span>
                      </b-col>
                      <b-col xl="4">
                        <span class=" text-white"> 67.4M MBT </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </card>
            </b-card-group>
          </b-col>
          <b-col xl="12">
            <b-card-group deck>
              <card gradient="default" no-body="">
                <b-card-body>
                  <div class="mb-2">
                    <b-row class="mb-2">
                      <b-col xl="12">
                        <sup class="text-white">TOKEN SUPPLY</sup>
                      </b-col>
                      <b-col xl="12">
                        <span class="h1 text-white">10.0935B MBT </span>
                      </b-col>

                      <b-col xl="8">
                        <span class=" text-white">Minted MBT </span>
                      </b-col>
                      <b-col xl="4">
                        <span class=" text-white"> 10.1B MBT </span>
                      </b-col>

                      <b-col xl="8">
                        <span class=" text-white">Burned MBT</span>
                      </b-col>
                      <b-col xl="4">
                        <span class=" text-white"> 13.0M MBT </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </card>

              <card gradient="default" no-body="">
                <b-card-body>
                  <div class="mb-2">
                    <b-row class="mb-2">
                      <b-col xl="12">
                        <sup class="text-white">PROTOCOL PARAMETERS</sup>
                      </b-col>

                      <b-col xl="9">
                        <span class=" text-white">Curation Reward </span>
                      </b-col>
                      <b-col xl="3">
                        <span class=" text-white">10% </span>
                      </b-col>

                      <b-col xl="9">
                        <span class=" text-white">Annual Token Issuance </span>
                      </b-col>
                      <b-col xl="3">
                        <span class=" text-white"> 3.00% </span>
                      </b-col>

                      <b-col xl="9">
                        <span class=" text-white"
                          >Indexer Slashing Percentage
                        </span>
                      </b-col>
                      <b-col xl="3">
                        <span class=" text-white"> 2.5% </span>
                      </b-col>

                      <b-col xl="9">
                        <span class=" text-white">Max Epoch Allocation </span>
                      </b-col>
                      <b-col xl="3">
                        <span class=" text-white"> 28 </span>
                      </b-col>

                      <b-col xl="9">
                        <span class=" text-white">Indexer Thawing Period </span>
                      </b-col>
                      <b-col xl="3">
                        <span class=" text-white"> 28 days </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </card>
            </b-card-group>
          </b-col>
        </b-col>

        <b-col xl="4">
          <b-card-group deck>
            <card gradient="default" no-body="">
              <b-card-body>
                <div class="mb-2">
                  <b-row class="mb-2">
                    <b-col xl="12">
                      <sup class="text-white">ACTIVE EPOCH</sup>
                    </b-col>
                    <b-col xl="4">
                      <span class="h1 text-white">140 </span>
                    </b-col>
                    <b-col xl="8" style="align-self: center;">
                      <span class="p text-white"> - 19 HOURS 32 MIN LEFT</span>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col xl="4">
                      <sup class="text-white">QUERY FEES</sup>
                    </b-col>
                    <b-col xl="8">
                      <sup class="text-white">INDEXING REWARDS</sup>
                    </b-col>
                    <b-col xl="4">
                      <span class="h1 text-white">26.1 MBT </span>
                    </b-col>
                    <b-col xl="8" style="align-self: center;">
                      <span class="h1 text-white">~ 0 MBT </span>
                    </b-col>
                  </b-row>
                  <div class="text-light mt-2 text-sm">
                    <EpochsTable></EpochsTable>
                  </div>
                </div>
              </b-card-body>
            </card>
          </b-card-group>
        </b-col>
      </b-row>
      <!--End Widgets-->
    </b-container>
  </div>
</template>
<script>
// Components
import BaseProgress from "@/components/BaseProgress";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import StatsCard from "@/components/Cards/StatsCard";
import EpochsTable from "./DashboardTable/EpochsTable";

export default {
  components: {
    BaseProgress,
    RouteBreadCrumb,
    EpochsTable,
    StatsCard
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>
<style></style>
